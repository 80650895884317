import React, { useLayoutEffect, useState, useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { LangContext } from "../context/LangContext"

import { AcfCoverMain } from "../components/gutenberg/acf-cover-main"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import { AcfCoverContent } from "../components/gutenberg/acf-cover-content"
import { Piagam } from "../components/gutenberg/shortcode-reports"
import { AcfTextWImgColWoContainer } from "../components/gutenberg/acf-text-w-image-wo-container"
import { Section } from "../components/anti/grid/grid"
import { CoreGroup } from "../components/gutenberg/core-group"
import { CoreParagraph } from "../components/gutenberg/core-paragraph"
import { AcfHeadline } from "../components/gutenberg/acf-headline"
import { AcfColHeadline } from "../components/gutenberg/acf-col-headline"
import { AcfIconList } from "../components/gutenberg/acf-iconlist"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const SustainabilityDetailsPage = ({ pageContext, location }) => {
  const { lang, setLang } = useContext(LangContext)

  const { seo } = pageContext.langKey === "en"
    ? pageContext.data
    : pageContext.data.translation
    || pageContext.data
  const wp = pageContext.data.blocks
  const wpID = pageContext.data.translation && pageContext.data.translation.blocks
  const reports = pageContext.report

  // Modify navbar style
  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  const Shortcode = ({ data }) => {
    if (reports && data.attributes.text === "sustainability-reports") {
      return <Piagam data={reports} id="sustainability" />
    }
  }

  const SEODefaultImg = lang === "EN"
    ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
    : pageContext?.data?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl
    || pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  //prettier-ignore
  return (
    <Layout>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={seo.opengraphImage?.sourceUrl || SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={seo.twitterImage?.sourceUrl || SEODefaultImg}
      />
      {lang == "ID" && wpID &&
        wpID.map((block, i) => {
          switch (block.name) {
            case "acf/cover-main":
              return <AcfCoverMain data={block} key={`${block.name}${i}`} breadcrumb={location.pathname} />
            case "acf/headline":
              return <AcfHeadline data={block} key={`${block.name}${i}`} />
            case "acf/col-headline":
              return <AcfColHeadline data={block} key={`${block.name}${i}`} />
            case "acf/acfgallery":
              return <AcfGallery className='py-0' data={block} key={`${block.name}${i}`} />
            case "acf/cover-content":
              return <AcfCoverContent data={block} key={`${block.name}${i}`} />
            case "core/shortcode":
              return <Shortcode data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-image-wo-container":
              return <AcfTextWImgColWoContainer data={block} key={`${block.name}${i}`} />
            case "acf/iconlist":
              return <AcfIconList data={block} key={`${block.name}${i}`} />
            case "core/paragraph":
              return (
                <Section className={`${block.attributes.backgroundColor}`} key={`${block.name}${i}`}>
                  <CoreParagraph data={block} />
                </Section>
              )
            case "core/group":
              return <CoreGroup data={block} key={`${block.name}${i}`} />
            default:
              break
          }
        })}
      {lang == "ID" && wpID == null &&
        wp.map((block, i) => {
          switch (block.name) {
            case "acf/cover-main":
              return <AcfCoverMain data={block} key={`${block.name}${i}`} breadcrumb={location.pathname} />
            case "acf/headline":
              return <AcfHeadline data={block} key={`${block.name}${i}`} />
            case "acf/col-headline":
              return <AcfColHeadline data={block} key={`${block.name}${i}`} />
            case "acf/acfgallery":
              return <AcfGallery className='py-0' data={block} key={`${block.name}${i}`} />
            case "acf/cover-content":
              return <AcfCoverContent data={block} key={`${block.name}${i}`} />
            case "core/shortcode":
              return <Shortcode data={block} key={`${block.name}${i}`} />
            case "acf/col-text-w-image-wo-container":
              return <AcfTextWImgColWoContainer data={block} key={`${block.name}${i}`} />
            case "acf/iconlist":
              return <AcfIconList data={block} key={`${block.name}${i}`} />
            case "core/paragraph":
              return (
                <Section className={`${block.attributes.backgroundColor}`} key={`${block.name}${i}`}>
                  <CoreParagraph data={block} />
                </Section>
              )
            case "core/group":
              return <CoreGroup data={block} key={`${block.name}${i}`} />
            default:
              break
          }
        })}
      {lang == "EN" && wp && wp.map((block, i) => {
        switch (block.name) {
          case "acf/cover-main":
            return <AcfCoverMain data={block} key={`${block.name}${i}`} breadcrumb={location.pathname} />
          case "acf/headline":
            return <AcfHeadline data={block} key={`${block.name}${i}`} />
          case "acf/col-headline":
            return <AcfColHeadline data={block} key={`${block.name}${i}`} />
          case "acf/acfgallery":
            return <AcfGallery className='py-0' data={block} key={`${block.name}${i}`} />
          case "acf/cover-content":
            return <AcfCoverContent data={block} key={`${block.name}${i}`} />
          case "core/shortcode":
            return <Shortcode data={block} key={`${block.name}${i}`} />
          case "acf/col-text-w-image-wo-container":
            return <AcfTextWImgColWoContainer data={block} key={`${block.name}${i}`} />
          case "acf/iconlist":
            return <AcfIconList data={block} key={`${block.name}${i}`} />
          case "core/paragraph":
            return (
              <Section className={`${block.attributes.backgroundColor}`} key={`${block.name}${i}`}>
                <CoreParagraph data={block} />
              </Section>
            )
          case "core/group":
            return <CoreGroup data={block} key={`${block.name}${i}`} />
          default:
            break
        }
      })}
    </Layout>
  )
}

export default SustainabilityDetailsPage
