import React from "react"
import PropTypes from "prop-types"

import { Button } from "../buttons/buttons"

//import icon from "../../assets/img/sample/icon-1.png"

export const Feature = ({
  img,
  icon,
  title,
  titleClassName,
  text,
  btnText,
  btnLink,
  className,
  children,
}) => {
  return (
    <>
      <div className={`feature ${className}`}>
        {img && <img className="feature-img" src={img} alt={title} />}
        <div className="feature-body">
          {title && (
            <h4
              className={`feature-title ${titleClassName}`}
              dangerouslySetInnerHTML={{ __html: title }}
            ></h4>
          )}
          {text && (
            <p
              className="feature-text"
              dangerouslySetInnerHTML={{ __html: text }}
            ></p>
          )}
          {btnText && (
            <Button variant="link" link={btnLink}>
              {btnText}
            </Button>
          )}
          {children}
        </div>
      </div>
    </>
  )
}

Feature.propTypes = {
  img: PropTypes.any, // Set feature image (commonly icon)
  title: PropTypes.string, // Set feature title
  titleClassName: PropTypes.string, // Set feature title optional classes
  text: PropTypes.string, // Set feature text
  className: PropTypes.string, // Set feature optional classes
  children: PropTypes.any,
}

Feature.defaultProps = {
  titleClassName: "",
  className: "",
}
